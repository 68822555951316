<template>
  <div :id="idDinamic" class="pa-1 scroll__dinamic">
    <slot />
  </div>
</template>

<script>
import { uuid } from "@/constants/helpers";
export default {
  props: {
    height: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      idDinamic: uuid(),
    };
  },
  mounted() {
    this.setClass();
  },
  methods: {
    setClass() {
      const scrollComponent = document.getElementById(this.idDinamic);
      scrollComponent.style.height = `${this.height}px`;
    },
  },
};
</script>

<style scoped>
.scroll__dinamic {
  overflow: scroll;
  overflow-x: unset;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #a9a9a9;
}

::-webkit-scrollbar-thumb {
  background: #757575;
}
::-webkit-scrollbar-thumb:hover {
  background: #2e3344;
}
</style>