import ApiService from '../Api';

class PriceableServices extends ApiService {
  constructor() {
    super('/archons');
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/priceables`, body);
  }

  update(archon, priceable, body) {
    return this.api.put(`${this.resource}/${archon}/priceables/${priceable}`, body);
  }

  destroy(archon, priceable) {
    return this.api.delete(`${this.resource}/${archon}/priceables/${priceable}`);
  }

  storeBulk(archon, body) {
    return this.api.post(`${this.resource}/${archon}/priceables/store-bulk`, body);
  }

  updateBulk(archon, body) {
    return this.api.post(`${this.resource}/${archon}/priceables/update-bulk`, body);
  }
}

export default new PriceableServices();