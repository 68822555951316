<template>
  <div>
    <Loader :loading="loading" />
    <v-row>
      <v-col cols="12" md="9" sm="12">
        <v-form ref="form" onsubmit="return false;">
          <v-row>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <SelectSupplier
                ref="selectEntities"
                v-model="supplier"
                label="Proveedor"
                outlined
                :rules="[isRequiredObj]"
                include="entity.mainContact"
                type-entity="SUPPLIER"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <v-text-field
                v-model="order.order_num"
                label="Pedido #"
                dense
                :rules="[isRequired]"
                :error-messages="errorNumber"
              />
              <!-- <v-textarea
                v-model="order.instructions"
                label="Instrucciones"
                dense
                outlined
                rows="2"
              /> -->
            </v-col>
          </v-row>
        </v-form>
        <Items
          :order-items="items"
          @onRemoveItem="onRemoveItem"
        />
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <InventoryBySupplier
          :height="heightInventories"
          ref="inventories"
          @onSendSku="onGetSku"
          :supplier="supplier"
        />
        <div class="d-flex justify-end">
          <v-text-field
            label="Total"
            :value="`Bs. ${total}`"          
            class="text-field-center"
            readonly
          />
        </div>
        <v-btn
          color="primary"
          small
          tile
          block
          elevation="0"
          @click="storeOrder"
          :disabled="!validateItems"
        >
          Terminar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// components
import Items from '../order/OrderItemsBuy';
import StatusServices from '@/store/services/general/StatusServices';
import InventoryBySupplier from '@/views/warehouse/order/InventoryBySupplier';
// services
import OrderServices from '@/store/services/warehouse/OrderServices';
// import SelectEntities from '@/components/commons/SelectEntities';
import SelectSupplier from '@/components/commons/SelectSupplier';
import PriceableServices from '@/store/services/general/PriceableServices';
// helpers
import { mapGetters } from 'vuex';
import { objectEmpty } from '@/constants/helpers';
export default {
  components: {
    InventoryBySupplier,
    Items,
    SelectSupplier,
    // SelectEntities,
  },
  data() {
    return {
      errorNumber: '',
      loading: false,

      items: [],
      warehouses: [],
      statuses: [],

      order: {
        user_id: '',
        origin_warehouse_id: null,
        destiny_warehouse_id: null,
        buy_contact_id: null,
        bill_contact_id: null,
        order_num: this.getNumPO(),
        instructions: '',
        total: 0,
        delivery_date: new Date(),
        type: '',
        status_id: '',
      },

      contactDestiny: {},

      //rules
      isRequired: value => !!value || 'Campo Requerido',
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',

      heightInventories: window.innerHeight - 350,
      heightItems: window.innerHeight - 190,

      supplier: {},
    };
  },
  created() {
    this.getStatuses();
  },
  methods: {
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    getNumPO() {
      const n = Math.round(Math.random()*999999 + 9999999);
      return `B-${n}`;
    },
    onGetSku(data) {
      const priceables = data.sku.priceables.filter(priceable => priceable.price.type === 'BUY');
      if (priceables.length === 0) {
        this.$notify({
          group: 'petition',
          type: 'info',
          title: data.sku.sku,
          text: `No tiene precio de compra`,
        });
        return;
      }
      const index = this.items.findIndex(item => item.sku_id === data.sku.id);
      if (index === -1) {
        const newItem = {
          sku_id: data.sku.id,
          sku: data.sku,
          sold_price: priceables[0].value,
          min_sold_price: priceables[0].value,
          quantity: 1,
          package_name: priceables[0].package.name,
          package_quantity: priceables[0].units,
          qty_available: data.qty_available,
          discount: 0,
          priceable: priceables[0] ?? null,
        };
        this.items.unshift(newItem);
      } else {
        this.$notify({
          group: 'petition',
          type: 'warning',
          title: data.sku.sku,
          text: `El sku ya fue agregado`,
        });
        return;
      }
    },
    onRemoveItem(data) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.sku_id);
      this.items.splice(index, 1);
    },
    async storeOrder() {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) {
          try {
            this.errorNumber = '';
            const status = this.getStatus('PENDING');
            this.order.status_id = status.id;
            this.order.user_id = this.user.id;
            this.order.destiny_warehouse_id = this.user.warehouse.id;
            this.order.type = 'BUY';
            this.order.buy_contact_id = this.supplier.entity.main_contact.id;
            this.order.bill_contact_id = this.supplier.entity.main_contact.id;
            this.order.total = this.total;
            this.order['items'] = this.items.map(item => ({
              sku_id: item.sku_id,
              quantity: item.quantity,
              sold_price: item.sold_price,
              package_name: item.package_name,
              package_quantity: item.package_quantity,
            }));
            this.loading = true;
            await OrderServices.store(this.user.archon.id, this.order);
            this.$router.push({
              name: 'buyList',
            });
            this.loading = false;
          } catch (error) {
            console.log(error)
            this.loading = false;
            this.$notify({
              group: 'petition',
              type: 'error',
              title: 'Pedido',
              text: `Pedido ${this.order.order_num} no se pudo guardar`,
            });
            if (error.response.status === 422) {
              const errorNumber = error.response.data.errors?.order_num[0] || null;
              if (errorNumber) {
                this.errorNumber = errorNumber;
              }
            }
          }
        } else this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Skus',
          text: `El pedido no tiene skus`,
        });
      }
    },
    async updatePriceables() {
      try {
        const priceables = this.items
          .filter(item => item.priceable && String(item.old_sold_price) !== String(item.sold_price))
          .map(item => ({
            priceable_type: item.priceable.priceable_type,
            priceable_id: item.priceable.priceable_id,
            package_id: item.priceable.package_id,
            unist: item.priceable.units,
            value: item.priceable.value
          }));
        await PriceableServices.updateBulk(this.user.archon.id, {
          priceables,
        })
      } catch (error) {
        this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Priceables',
          text: 'Priceables no actualizados',
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    validateItems() {
      return this.items.filter(sku => parseFloat(sku.discount) > (parseInt(sku.quantity) * parseFloat(sku.sold_price))).length === 0;
    },
    total() {
      let total = 0;
      this.items.forEach(item => {
        total += parseFloat(item.sold_price * item.quantity) - parseFloat(item.discount);
      });
      return total.toFixed(2);
    },
    currentSupplier() {
      return this.contactDestiny?.contactable?.supplier ?? {};
    },
  },
}
</script>

<style scoped>
.text-field-center >>> input {
  text-align: center;
}
</style>