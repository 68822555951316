export default {
  data() {
    return {
      lastPage: 1,
      total: 0,
      perPage: 10,
      currentPage: 1,
      listPerPage: [10, 50, 100, 200, 500, 'Todo'],
    };
  },
};