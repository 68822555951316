import ApiService from '../Api';

class SkuServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/skus`, { params });
  }

  storeBulk(archon, body) {
    return this.api.post(`${this.resource}/${archon}/skus/store-bulk`, body);
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/skus`, body);
  }

  update(archon, skuId, body) {
    return this.api.put(`${this.resource}/${archon}/skus/${skuId}`, body)
  }
}

export default new SkuServices();