<template>
  <v-card flat>
    <LoaderDotSpinner absolute :loading="loading"/>
    <v-card-title class="pb-6">
      <v-icon left>mdi-account-switch</v-icon>
      Proveedor
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="entity.name"
              label="Proveedor"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="entity.nit"
              :label="$t('nit')"
              type="number"
              dense
              outlined
              :rules="[isNit]"
            />
          </v-col>
          <v-col cols="12" md="4" sm="12" class="py-0">
            <v-text-field
              v-model="supplier.processing_time"
              :label="$t('processingTime')"
              dense
              outlined
              type="number"
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="4" sm="12" class="py-0">
            <v-select
              v-model="supplier.period"
              :items="supplierPeriods"
              item-text="text"
              item-value="value"
              :label="$t('period')"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="4" sm="12" class="py-0">
            <v-text-field
              v-model="entity.description"
              label="Código"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0" v-if="!edit">
            <v-text-field
              v-model="contact.name"
              label="Nombre"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0" v-if="!edit">
            <v-text-field
              v-model="contact.last_name"
              label="Apellido"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0" v-if="!edit">
            <v-text-field
              v-model="contact.phone1"
              label="Telefono"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0" v-if="!edit">
            <v-text-field
              v-model="contact.email1"
              label="Email"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0" v-if="!edit">
            <v-text-field
              v-model="address.state"
              label="Departamento"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0" v-if="!edit">
            <v-text-field
              v-model="address.address1"
              label="Dirección"
              dense
              outlined
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0" v-if="!edit">
            <v-textarea
              v-model="contact.notes"
              label="Notas"
              rows="2"
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        color="grey lighten-1"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        v-if="!edit"
        small
        color="success"
        @click="saveCustomer"
      >
        {{ $t('save') }}
      </v-btn>
      <v-btn
        v-else
        small
        color="success"
        @click="editCustomer"
      >
        Actualizar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EntityServices from '@/store/services/general/EntityServices';
import SupplierServices from '@/store/services/general/SupplierServices';
import ContactServices from '@/store/services/general/ContactServices';
import AddressServices from '@/store/services/general/AddressServices';
import { mapGetters } from 'vuex';
import { isNit } from '@/constants/rules';
export default {
  props: {},
  data() {
    return {
      edit: false,
      entity: {
        name: '',
        nit: '',
        type: 'SUPPLIER',
        description: ''
      },
      supplier: {
        entity_id: -1,
        processing_time: '1',
        period: 'DAYS'
      },
       // contact
      contact: {
        user_id: '',
        name: '',
        last_name: '',
        ic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        type: 'MAIN',
        status: 'ACTIVE',
        contactable_type: 'entity',
        contactable_id: -1,
      },
      // address
      address: {
        address1: '',
        address2: '',
        addressable: '',
        country: 'Bolivia',
        state: '',
        city: '',
        zip_code: '',
        latitude: '',
        longitude: '',
        addressable_type: 'entity',
        addressable_id: -1,
      },
      supplierPeriods: [
        {
          value: 'HOURS',
          text: this.$t('hours')
        },
        {
          value: 'DAYS',
          text: this.$t('days')
        },
        {
          value: 'WEEKS',
          text: this.$t('weeks')
        }
      ],
      loading: false,
      // rules
      requiredRule: v => !!v || this.$t('requiredField'),
      isNit: value => isNit(value) || 'Nit Inválido',

      supplierId: -1,
      entityId: -1,
      contactId: -1,
      addressId: -1,
    }
  },
  methods: {
    cancel() {
      this.$emit('onCancel')
    },
    async saveCustomer() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const responseEntity = await EntityServices.store(this.user.archon.id, this.entity);
          this.supplier.entity_id = responseEntity.data.id;
          const supplierResponse = await SupplierServices.store(this.user.archon.id, this.supplier);
          this.contact.contactable_id = responseEntity.data.id;
          this.address.addressable_id = responseEntity.data.id;
          const responseContact = await ContactServices.store2(this.user.archon.id, this.contact);
          await AddressServices.store(this.user.archon.id, this.address);

          const newSupplier = supplierResponse.data;
          newSupplier['entity'] = responseEntity.data;
          this.$emit('onCreated', {
            supplier: newSupplier,
            contact: responseContact,
            type: 'store',
          });
          this.resetForm()
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log(error)
        }
      }
    },
    async editCustomer() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const responseEntity = await EntityServices.update(this.user.archon.id, this.entityId, this.entity);
          const supplierResponse = await SupplierServices.update(this.user.archon.id, this.supplierId, this.supplier);

          const newSupplier = supplierResponse.data;
          newSupplier['entity'] = responseEntity.data;
          this.$emit('onCreated', {
            supplier: newSupplier,
            type: 'update',
          });
          this.resetForm()
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log(error)
        }
      }
    },
    resetForm() {
      this.entity = {
        name: '',
        nit: '',
        type: 'SUPPLIER',
        description: '',
      };
      this.supplier = {
        entity_id: -1,
        processing_time: '1',
        period: 'DAYS',
      };
       // contact
      this.contact = {
        user_id: '',
        name: '',
        last_name: '',
        ic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        type: 'MAIN',
        status: 'ACTIVE',
        contactable_type: 'entity',
        contactable_id: -1,
      };
      // address
      this.address = {
        address1: '',
        address2: '',
        country: 'Bolivia',
        state: '',
        city: '',
        zip_code: '',
        latitude: '',
        longitude: '',
        addressable_type: 'entity',
        addressable_id: -1,
      };
      this.$refs.form.resetValidation();

      this.edit = false;
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    'entity.name'(value) {
      let code = '';
      if (value.length >= 3) {
        const c1 = value[0].toUpperCase();
        const pointer = Math.trunc(value.length / 2);
        const c2 = value[pointer]  === ' ' ? 'X' : value[pointer].toUpperCase();
        const c3 = value[value.length - 1].toUpperCase();
        code = `${c1}${c2}${c3}`;
      }
      this.entity.description = code;
    },
    'address.state'(value) {
      this.address.city = value;
    },
  }
}
</script>

<style>

</style>