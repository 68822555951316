import ApiService from '../Api';

class StatusServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/status`, { params });
  }
}

export default new StatusServices();