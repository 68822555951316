<template>
  <v-card flat min-height="100">
    <LoaderDotSpinner absolute :loading="loading" />
    <div class="d-flex justify-space-between px-2">
      <div style="width: 150px;">
        <v-text-field
          v-model="search"
          dense
          label="Buscar"
          type="text"
        />
      </div>
      <v-btn
        @click="getInventories"
        color="warning"
        small
        text
      >
        <v-icon left>mdi-refresh</v-icon>
        Refrescar
      </v-btn>
    </div>
    <TScroll :height="height">
      <v-list two-line>
        <v-divider />
        <template v-for="(item, index) in inventories">
          <v-list-item
            link
            :key="`inv-${index}-${item.id}`"
          >
            <v-list-item-content @click="addSkuToOrder(item, index)">
              <v-list-item-title>
                <div><b>{{ item.sku }}</b></div>
                <div style="font-size: 10px;"> {{ item.product.name }} - <b>{{ item.description }}</b> </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div style="font-size: 10px;">
                  En almacén: <b> {{ getQtyAvailable(item.liq) }} </b>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="info"
                icon
                large
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`div-${index}-${item.id}`" />
        </template>
      </v-list>
    </TScroll>
  </v-card>
</template>

<script>
import TScroll from '@/components/commons/tiny/TScrollSimple';
import SkuServices from '@/store/services/inventory/SkuServices';
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
export default {
  mixins: [ListMixin],
  components: {
    TScroll,
  },
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      loading: false,
      inventories: [],

      search: '',
    };
  },
  mounted() {
    // this.getInventories();
  },
  methods: {
    async getInventories() {
      try {
        const params = {
          search: this.search,
          // page: this.currentPage,
          order_by: 'created_at',
          order_direction: 'DESC',
          // per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'product,priceables.price,priceables.package,liq',
          search_supplier: this.supplier.id,
        };
        this.loading = true;
        const response = await SkuServices.index(this.user.archon.id, params);
        this.inventories = response.data.data;
        // this.currentPage = response.data.current_page;
        // this.lastPage = response.data.last_page;
        // this.total = response.data.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getInventories();
      else this.currentPage = 1;
    },
    addSkuToOrder(item, index) {
      this.$emit('onSendSku', {
        index,
        qty_available: this.getQtyAvailable(item.liq),
        sku: item,
      });
    },
    getQtyAvailable(liq) {
      const indexLiq = liq.findIndex(inv => inv.warehouse_id === this.user.warehouse.id);
      return indexLiq === -1 ? 0 : liq[indexLiq].qty_available;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    currentPage() {
      this.getInventories();
    },
    warehouse() {
      this.getInventories();
    },
    supplier: {
      deep: true,
      immediate: true,
      handler() {
        const supplierId = this.supplier?.id ?? -1;
        if (supplierId !== -1) this.getInventories();
      },
    },
  },
}
</script>