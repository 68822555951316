import ApiService from '../Api';

class EntityServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/entities`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/entities`, body);
  }

  update(archon, warehouse, body) {
    return this.api.put(`${this.resource}/${archon}/entities/${warehouse}`, body);
  }
}

export default new EntityServices();