export const objectEmpty = (obj) => {
  let cont = 0;
  // eslint-disable-next-line
  for(var key in obj) {
    cont++;
  }
  return cont === 0;
};

export const uuid = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

export const getDateFormat = (date, withHour = false) => {
  const newDate = new Date(date);
  const month = newDate.getUTCMonth() + 1;
  const day = newDate.getUTCDate();
  const year = newDate.getUTCFullYear();
  const result = `${day}/${month}/${year}`;
  if (withHour) {
    const hour = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours();
    const mins = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes();
    // const seg = newDate.getSeconds();
    return `${result} ${hour}:${mins}`;
  }
  return result
};

export const textSelect = (id) => {
  const input = document.getElementById(id);
  input.focus();
  input.select();
};