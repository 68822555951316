<template>
  <div style="width: 100%;">
    <v-autocomplete
      v-model="selected"
      :items="suppliers"
      :loading="loading"
      :search-input.sync="search"
      :rules="rules"
      item-text="name"
      item-value="id"
      :label="label"
      placeholder="Buscar"
      return-object
      dense
      attach
      :filter="customFilter"
    >
      <template v-slot:selection="data">
        <v-list-item-content v-bind="data.attrs">
          <v-list-item-subtitle>
            <div>
              <v-icon left small>mdi-account-outline</v-icon>
              <small> {{ data.item.entity.name }}</small>
            </div>
            <div>
              <v-icon left small>mdi-card-account-details-outline</v-icon>
              <small> {{ data.item.entity.nit }} </small>
            </div>
            <div>
              <v-icon left small>mdi-clock-outline</v-icon>
              <small>
                {{ data.item.processing_time }} {{ data.item.period }}
              </small>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-subtitle>
            <div>
              <v-icon left small>mdi-account-outline</v-icon>
              <small> {{ data.item.entity.name }} </small>
            </div>
            <div>
              <v-icon left small>mdi-card-account-details-outline</v-icon>
              <small> {{ data.item.entity.nit }} </small>
            </div>
            <div>
              <v-icon left small>mdi-clock-outline</v-icon>
              <small>
                {{ data.item.processing_time }} {{ data.item.period }}
              </small>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-dialog
      v-model="dialogSupplier"
      width="700"
      persistent
    >
      <SupplierForm
        ref="supplierForm"
        @onCreated="supplierCreated"
        @onCancel="dialogSupplier = false"
      />
    </v-dialog>
  </div>
</template>
  
<script>
import SupplierServices from '@/store/services/general/SupplierServices';
import SupplierForm from '@/views/general/supplier/SupplierForm';
import { mapGetters } from 'vuex';
export default {
  components: {
    SupplierForm,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    include: {
      type: String,
      default: '',
    },
    typeEntity: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
      search: '',

      loading: false,
      suppliers: [],

      dialogSupplier: false,
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    supplierCreated(data) {
      const supplier = data.supplier;
      supplier.entity['main_contact'] = data.contact;
      this.$refs.supplierList.addSupplier(data.supplier);
      this.dialogSupplier = false;
    },
    customFilter (item, queryText) {
      const name = item.name.toLowerCase();
      const last_name = item.last_name.toLowerCase();
      const ic = item.ic.toLowerCase();
      const email1 = item.email1.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        name.indexOf(searchText) > -1 || 
        last_name.indexOf(searchText) > -1 ||
        ic.indexOf(searchText) > -1 ||
        email1.indexOf(searchText) > -1
      );
    },
    async getSuppliers() {
      try {
        this.loading = true;
        const params = {
          order_by: 'entity_id',
          order_direction: 'ASC',
          contactable_type: 'entity',
          include: this.include,
          // search_all: this.search,
          search_entity: this.typeEntity,
          search: this.typeEntity,
          models: ['entity.contacts'],
          search_contact_type: ['MAIN']
          // per_page: 20,
          // page: 1,
        };
        const response = await SupplierServices.index(
          this.user.archon.id,
          params
        )
        
        this.suppliers = response.data.data;
        console.log(this.suppliers)
        // if (response.data.data.length > 0) {
        //   this.search = '';
        // } 
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createEntity() {
      this.$refs['createEntity'].dialog = true;
    },
    setEntities() {
      if (this.entity) {
        if (Object.keys(this.entity).length > 0) {
          console.log(this.entity)
          console.log(this.entity[0])
          this.selected = this.entity
        }
      }
    },
    setSelected() {
      console.log(this.selected)
      this.$emit('input', this.selected);
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler: 'setSelected'
    },
    entity: {
      deep: true,
      immediate: true,
      handler: 'setEntities'
    }
  },
};
</script>