import ApiService from '../Api';

class OrderServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/orders`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/orders`, body);
  }

  update(archon, order, body) {
    return this.api.put(`${this.resource}/${archon}/orders/${order}`, body);
  }

  show(archon, order, params) {
    return this.api.get(`${this.resource}/${archon}/orders/${order}`, { params });
  }

  reports(archon, params) {
    return this.api.get(`${this.resource}/${archon}/order-report`, params)
  }

  file(archon, orderId, params) {
    return this.api.get(`${this.resource}/${archon}/orders/${orderId}/file`, params);
  }
  
  cancelOrder(archon, orderId, params) {
    return this.api.put(`${this.resource}/${archon}/orders/${orderId}/cancel`, params);
  }
}

export default new OrderServices();